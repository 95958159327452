import React, { useState, useMemo, useRef, useEffect } from 'react';
import './Certifications.css';
import Certification from "./Certification";
import back_icon from '../../img/back-icon.png';
import CompositionGraph from "./CompositionGraph.jsx";

const Certifications = ({
  certifications,
  recycled,
  compositionList,
  touchAreaRef,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullExpanded, setIsFullExpanded] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const containerRef = useRef(null);

  const toggleFullExpand = () => {
    setIsExpanded(!isExpanded);
    setIsFullExpanded((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
  };

  const handleTouchMove = (e) => {
    if (!startX || !startY) {
      return;
    }

    const touch = e.touches[0];
    const diffX = startX - touch.clientX;
    const diffY = startY - touch.clientY;

    // Detect swipe left
    if (Math.abs(diffX) > Math.abs(diffY) && diffX > 50) {
      setIsExpanded(true);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
    setStartY(null);
  };

  const renderCertifications = () => {
    if (!certifications || certifications.length === 0) {
      return (
        <div className="no-certifications">
          <p>This product doesn't have any certifications.</p>
        </div>
      );
    }
    return (
      <div className="certifications-map">
        {certifications.slice(0, 3).map((certification) => (
          <div key={certification.id} className="certification-item">
            <Certification
              img={certification.img}
              title={certification.title}
              description={certification.description}
            />
          </div>
        ))}
      </div>
    );
  };

  const memoizedCompositionGraph = useMemo(
    () => (
      <CompositionGraph compositionList={compositionList} recycled={recycled} />
    ),
    [compositionList, recycled]
  );

  const combinedRef = (node) => {
    containerRef.current = node;
    if (touchAreaRef) {
      touchAreaRef.current = node;
    }
  };

  return (
    <div
      className="outer-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {!isExpanded && !isFullExpanded && (
        <div className="edge" onClick={() => setIsExpanded(true)}>
          <img
            className="arrow-icon-edge"
            src="./images/arrow_down.svg"
            alt="Arrow icon"
          />
        </div>
      )}
      <div
        ref={combinedRef}
        className={`container-certifications-v2 ${
          isExpanded ? "expanded" : "collapsed"
        } ${isFullExpanded ? "full-expanded" : ""}`}
      >
        {isExpanded && (
          <div className="title-certifications-v2">
            <div className="icon-close-certifications">
              <img
                className="icon-close-certifications"
                src="./images/close-icon.svg"
                alt="Close icon"
                onClick={() => setIsExpanded(false)}
              />
            </div>
            Certifications
          </div>
        )}
        <div
          className={`certifications ${
            !certifications || (certifications.length === 0 && isExpanded)
              ? "nocert"
              : ""
          }`}
        >
          {!isFullExpanded && (
            <div className="certifications-estranha">
              {!isExpanded && (
                <div
                  className="image-container-first"
                  onClick={() => setIsExpanded(true)}
                >
                  <img
                    className="icon-blabla"
                    src="./images/icon_certificacoes_edited.svg"
                    alt=""
                  />
                  <div className="horizontal-line"></div>
                  <img
                    className="icon-blabla"
                    src="./images/icon_composition_popup.svg"
                    alt=""
                  />
                </div>
              )}
              {isExpanded && renderCertifications()}
            </div>
          )}
        </div>
        {isFullExpanded && (
          <>
            <div className="title-full">
              <img
                className="icon-back-certifications"
                src={back_icon}
                alt="Back icon"
                onClick={() => toggleFullExpand()}
              />
              Certifications
            </div>
          </>
        )}
        {isFullExpanded && (
          <div className="certificationss-expanded">
            {certifications.map((certification) => (
              <div
                key={certification.id}
                className="certification-item-expanded"
              >
                <Certification
                  img={certification.img}
                  title={certification.title}
                  description={certification.description}
                />
              </div>
            ))}
          </div>
        )}
        {isExpanded && certifications.length > 3 && (
          <div className="see-more-container">
            <p className="see-more-text" onClick={toggleFullExpand}>
              See More
            </p>
          </div>
        )}
        {isExpanded && (
          <div className="composition-container-cert">
            <div className="composition-container-cert-p">Composition</div>
            {memoizedCompositionGraph}
          </div>
        )}
      </div>
    </div>
  );
};

export default Certifications;
