import React from "react";
import "./ScoreV2.css";

//componente para mostrar o score
// tem como parametros apenas um valor numerico do score do artigo
// tem uma função para verificar em que faixa de classificação se adequa (letras)
// atribui a cor conforme a letra definida
// tem função onClick para trocar  value entre o valor recebido (%) e a respetiva letra

const Score = ({ score }) => {


  const getLetterGrade = (score) => {
    if (score < 0 || score > 100) {
      return "Null";
    } else if (score >= 85.0) {
      return "A";
    } else if (score >= 75.0) {
      return "B";
    } else if (score >= 65.0) {
      return "C";
    } else if (score >= 55.0) {
      return "D";
    } else if (score >= 45.0) {
      return "E";
    } else if (score < 45.0) {
      return "F";
    } else {
      return "Null";
    }
  };

  const letterGrade = getLetterGrade(score);

  const getLetterGradeColor = (grade) => {
    switch (grade) {
      case "A":
        return "#59c0e9";
      case "B":
        return "#00a2c9";
      case "C":
        return "#00a3b2";
      case "D":
        return "#006e8f";
      case "E":
        return "#002a69";
      case "F":
        return "#001c4a";
      default:
        return "#000000";
    }
  };

  const textColor = getLetterGradeColor(letterGrade);

  return (
    <div className="score-container-headerv2">
      <div className="score-title-headerv2">Score</div>
      <div className="score-value-headerv2" style={{ color: textColor }}>
        {score}%
      </div>
    </div>
  );
};

export default Score;
