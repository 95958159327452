import React from 'react';
import "./Certification.css";

const Certification = ({ img, title, description }) => {
  const isDefaultIcon = !img;

  return (
    <div className="certification-container">
      <div className="image-container">
        <img 
          className={`item-image ${isDefaultIcon ? 'default-icon' : ''}`} 
          src={img || './images/icon_default_not_certification.svg'} 
          alt={title || 'Certification Icon'} 
        />
      </div>
      <div className="items-container">
        <div className="item-title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default Certification;
