// App.js

import React from 'react';
import AppV1 from './V1_App/App'; // Importa a versão 1 do App
import AppV2 from './V2_App/App'; // Importa a versão 2 do App

const useVersion1 = false; // Define qual versão usar

function App() {
  return (
    <div className="App">
      {useVersion1 ? <AppV1 /> : <AppV2 />}
    </div>
  );
}

export default App;
