import React, { useRef, useState, useEffect } from 'react';
import "./SlideContentV2.css";

//componente para  mostrar diferentes conteudos na homepage com swipes horizontais
//contem um indicador de tabs em baixo com bolinhas para o utilizador ter uma noção em que tab está (são clickable)
//como deve ser passada a lista na parent component:
/* const homepageSlideContent = [
    {
      id: 1,
      img: "../images/dpp-1.png",
    },
    {
      id: 2,
      img: "../images/dpp-2.png",
    },
    {
      id: 3,
      img: "../images/dpp-3.png",
    },
  ]; */
    
    const delay = 4000;
    
    const SlideContentV2 = ({ homepageSlideContent }) => {
      const [index, setIndex] = useState(0);
      const [startX, setStartX] = useState(null);
      const [isSwiping, setIsSwiping] = useState(false);
      const timeoutRef = useRef(null);

      function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }

      useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
          setIndex((prevIndex) =>
            prevIndex === homepageSlideContent.length - 1 ? 0 : prevIndex + 1
          );
        }, delay);

        return () => {
          resetTimeout();
        };
      }, [index, homepageSlideContent]);

      const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
        resetTimeout();
      };

      const handleTouchMove = (e) => {
        if (!isSwiping) return;

        const currentX = e.touches[0].clientX;
        const diffX = startX - currentX;

        // You can set a threshold value to determine whether the swipe is horizontal enough
        const threshold = 50;

        if (Math.abs(diffX) > threshold) {
          if (diffX > 0) {
            // Swiping left
            setIndex((prevIndex) =>
              prevIndex === homepageSlideContent.length - 1 ? 0 : prevIndex + 1
            );
          } else {
            // Swiping right
            setIndex((prevIndex) =>
              prevIndex === 0 ? homepageSlideContent.length - 1 : prevIndex - 1
            );
          }

          setIsSwiping(false);
        }
      };

      const handleTouchEnd = () => {
        setIsSwiping(false);
        resetTimeout();
      };

      return (
        <div className="slideshow-padding">
          <div className="slideshow-shadow">
            <div
              className="slideshow"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div
                className="slideshowSlider"
                style={{ transform: `translateX(${-index * 100}%)` }}
              >
                {homepageSlideContent.map((slide, idx) => (
                  <div
                    key={idx}
                    className={`slide-v2 ${idx === index ? "active" : ""}`}
                  >
                    <img
                      className="slide-img-v2"
                      src={slide.img || "/images/image-coming-soon.jpg"}
                      alt={`Slide ${idx + 1}`}
                    />
                  </div>
                ))}
              </div>

              <div className="slideshowDots">
                {homepageSlideContent.map((_, idx) => (
                  <div
                    key={idx}
                    className={`slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default SlideContentV2;
    
