import React, { useState, useRef, useEffect } from "react";
import FilterComponent from "../../components/v2/filters/scoreFilter";
import "./Filters.css";
import filters_close from "../../img/filters-close.svg";

const Filters = ({
  brands,
  descriptions,
  onFiltersChange,
  onApplyFilters,
  initialSelectedTypes,
  initialScoreRange,
  initialSelectedBrands,
  onClickOutside,
}) => {
  const [selectedTypes, setSelectedTypes] = useState(initialSelectedTypes);
  const [convertedRange, setConvertedRange] = useState(initialScoreRange);
  const [selectedBrands, setSelectedBrands] = useState(initialSelectedBrands);
  const [menuCloseApply, setmenuCloseApply] = useState([false]);
  const [isSearching, setIsSearching] = useState(false);
  const filterChildComponentRef = useRef();
  const searchInputRef = useRef();
  const [filteredBrands, setFilteredBrands] = useState(selectedBrands);

  useEffect(() => {
    setSelectedTypes(initialSelectedTypes);
    setConvertedRange(initialScoreRange);
    setSelectedBrands(initialSelectedBrands);
    setFilteredBrands(brands);
    if (
      filterChildComponentRef.current &&
      typeof filterChildComponentRef.current.setRange === "function"
    ) {
      filterChildComponentRef.current.setRange(initialScoreRange);
    }
  }, [initialSelectedTypes, initialScoreRange, initialSelectedBrands]);

  useEffect(() => {
    if (!isSearching) {
      setFilteredBrands(brands);
    }
  }, [isSearching, brands]);

  // Função para lidar com o clique em uma marca
  const handleBrandClick = (brand) => {
    // Verifica se a marca já está na lista de selecionados
    const isBrandSelected = selectedBrands.includes(brand);

    if (isBrandSelected) {
      // Remove a marca se já estiver selecionada
      setSelectedBrands(
        selectedBrands.filter((selectedBrand) => selectedBrand !== brand)
      );
    } else {
      // Adiciona a marca se não estiver selecionada
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedTypes((prevSelectedTypes) =>
      checked
        ? [...prevSelectedTypes, value]
        : prevSelectedTypes.filter((type) => type !== value)
    );
  };

  const handleRangeChange = (newRange) => {
    setConvertedRange(newRange);
  };

  const handleApplyFilters = () => {
    onFiltersChange(selectedTypes, convertedRange, selectedBrands);
    onApplyFilters(menuCloseApply);
  };

  const handleShowFilters = () => {
    onClickOutside(false);
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = brands.filter((brand) =>
      brand.toLowerCase().includes(searchTerm)
    );
    setFilteredBrands(filtered);
  };

  const handleClearFilters = () => {
    setSelectedTypes([]);
    setSelectedBrands([]);
    if (
      filterChildComponentRef.current &&
      typeof filterChildComponentRef.current.reset === "function"
    ) {
      filterChildComponentRef.current.reset();
    }
    onFiltersChange([], [0, 100], []);
  };

  const handleSearchIconClick = () => {
    setIsSearching(!isSearching);
  };

  const checkboxValues = descriptions.map((item) => ({
    label: item.description,
    value: item.code,
  }));

  return (
    <>
      <div className="swipe-panel-filters open">
        <div className="container-filters-max-width">
          <div className="container-filters">
            <div className="score">
              <div className="score-text-filters">Score</div>
              <FilterComponent
                onRangeChange={handleRangeChange}
                ref={filterChildComponentRef}
              />
            </div>
            <div className="brands-filters">
              <div className="brands-title-filters">
                Brand{" "}
                {!isSearching ? (
                  <img
                    className="icon-searchinput-v2-filters"
                    alt="icon-input"
                    src="./images/search_lupa.svg"
                    onClick={handleSearchIconClick}
                  />
                ) : (
                  <img
                    className="icon-searchinput-v2-filters"
                    alt="icon-input"
                    src={filters_close}
                    onClick={handleSearchIconClick}
                  />
                )}
              </div>

              {isSearching && (
                <div className="searchbar-filters-container">
                  <div className="searchbar-v2-filters">
                    <div className="searchbar-v2-input-component-filters">
                      <img
                        className="icon-searchinput-v2-filters"
                        alt="icon-input"
                        src="./images/search_lupa.svg"
                      />
                      <input
                        className="searchinput-value-v2-filters"
                        placeholder="Search by Brand"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="brands-container-filters">
                {filteredBrands.map((brand, index) => (
                  <div
                    key={index}
                    className={`brands-showcase `}
                    onClick={() => handleBrandClick(brand)}
                  >
                    <div
                      className={`brands-content-fitted ${
                        selectedBrands.includes(brand) ? "selected" : ""
                      }`}
                    >
                      {" "}
                      {brand}{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="type">
              <div className="type-title">Type of Product</div>
              <div className="types-container">
                {checkboxValues.map((checkbox) => (
                  <label key={checkbox.value} className="checkbox-container">
                    <input
                      type="checkbox"
                      value={checkbox.value}
                      checked={selectedTypes.includes(checkbox.value)}
                      onChange={handleCheckboxChange}
                    />
                    <span className="custom-checkbox"></span>
                    <div
                      className={`text-custom-checkbox ${
                        selectedTypes.includes(checkbox.value)
                          ? "text-blue"
                          : ""
                      }`}
                    >
                      {checkbox.label}{" "}
                    </div>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="bottom-filters">
            <div className="clear-filters" onClick={handleClearFilters}>
              Clear
            </div>
            <div className="apply-filters" onClick={handleApplyFilters}>
              Apply
            </div>
          </div>
        </div>
      </div>
      <div className="max-height-filters" onClick={handleShowFilters}></div>
    </>
  );
};

export default Filters;
