import React, { useState, useEffect, useRef } from "react";
import "./Products.css";

// Import components
import ProductItem from "../../components/v2/ProductItemProducts";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import FiltersComponent from "../../components/v2/Filters";

// Icons
import ProductsView from "../../img/products-view.svg";
import ProductsViewSecond from "../../img/products-view-second.svg";
import Filters from "../../img/filters.svg";
import FiltersClose from "../../img/filters-close.svg";

// Importar Product-type
import product_type from "./jsons/type.json";

const Products = ({ productList }) => {
  const [viewInLine, setViewInLine] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const productItemsRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  // Para o componente Filters
  const [productId, setProductId] = useState("");
  const [brands, setBrands] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [ScoreRange, setScoreRange] = useState([0, 100]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const scrollableElement = productItemsRef.current;

    if (scrollableElement) {
      scrollableElement.classList.add('scrollable');
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.classList.remove('scrollable');
      }
    };
  }, []);

  useEffect(() => {
    const handlePageShow = () => {
      const scrollableElement = productItemsRef.current;

      if (scrollableElement) {
        scrollableElement.classList.add('scrollable');
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const handleClickFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCloseMenuApplyFilters = (menuclose) => {
    setShowFilters(!showFilters);
  };

  const filteredProducts = hasFilters
    ? productList.filter((product) => {
        const brandCondition =
          selectedBrands.length === 0 || selectedBrands.includes(product.brand);
        const scoreCondition =
          product.score >= ScoreRange[0] && product.score <= ScoreRange[1];
        const typeCondition =
          selectedTypes.length === 0 || selectedTypes.includes(product.code);
        const searchBoxCondition = product.id
          .toString()
          .toLowerCase()
          .includes(productId.toLowerCase());
        return (
          scoreCondition &&
          brandCondition &&
          typeCondition &&
          searchBoxCondition
        );
      })
    : productList.filter((product) => {
        // Segunda condição: verifica se o productId é uma string vazia ou se está contido no ID do produto

        return (
          productId === "" ||
          product.id.toString().toLowerCase().includes(productId.toLowerCase())
        );
      });

  // Sorteia por score
  filteredProducts.sort((a, b) => b.score - a.score);

  /*
  const filteredProducts = productList.filter(product =>
    productId === "" || product.id.toString().toLowerCase().includes(productId.toLowerCase())
  ); */

  //Obtem os seleccionados da Filters
  const handleGetFilters = (selectedTypes, convertedRange, selectedBrands) => {
    //console.log("Filters selected in parent:", { selectedTypes, convertedRange, selectedBrands });
    setHasFilters(true);
    setScoreRange(convertedRange);
    setSelectedBrands(selectedBrands);
    setSelectedTypes(selectedTypes);

    //console.log("score range" + ScoreRange)
    //console.log("hasfilters" + hasFilters)
  };

  //console.log(productList);

  const handleClickOutside = (ClickOutside) => {
    setShowFilters(ClickOutside);
  }

  useEffect(() => {
    const uniqueBrands = [
      ...new Set(productList.map((product) => product.brand)),
    ];
    setBrands(uniqueBrands);

    const allAssociations = [];
    product_type.forEach((category) => {
      // allAssociations.push({ code: category.code, description: category.description.en });
      category.options.forEach((option) => {
        allAssociations.push({
          code: option.code,
          description: option.description.en,
        });
      });
    });
    console.log(brands);
    setDescriptions(allAssociations);
    //console.log("Product List" + productList)
  }, [productList, product_type]);

  return (
    <div className="page-products-container">
      <div className="top-info">
        <div className="title">Products</div>
        <div className="filters-and-group-by">
          <div className="filters" onClick={handleClickFilters}>
            Filters
            {showFilters ? (
              <img className="filters-img-close" src={FiltersClose} alt="" />
            ) : (
              <img src={Filters} alt="" />
            )}
          </div>

          <div className="group-by">
            <div className="icon" onClick={() => setViewInLine(false)}>
              <img src={ProductsView} alt="view1" />
            </div>
            <div className="icon-second" onClick={() => setViewInLine(true)}>
              <img src={ProductsViewSecond} alt="view2" />
            </div>
          </div>
        </div>
        {showFilters && (
          <FiltersComponent
            brands={brands}
            descriptions={descriptions}
            onFiltersChange={handleGetFilters}
            onApplyFilters={handleCloseMenuApplyFilters}
            initialSelectedTypes={selectedTypes}
            initialScoreRange={ScoreRange}
            initialSelectedBrands={selectedBrands}
            onClickOutside={handleClickOutside}
          />
        )}
      </div>
      <div
        className={
          !viewInLine ? "page-products-content" : "page-products-content-view"
        }
      >
        <div
          className={
            viewInLine ? "page-product-items-inline" : "page-product-items"
          }
          ref={productItemsRef}
        >
          {filteredProducts.map((product) => (
            <div
              className={
                !viewInLine ? "page-product-item" : "page-product-item-inline"
              }
              key={product.id}
            >
              <ProductItem productObject={product} />
            </div>
          ))}
        </div>
      </div>

      <div className="navbar-footer">
        <Navbar MenuClick={handleOpenMenu}></Navbar>
        <OverlayComponent isOpen={isMenuOpen} onClose={handleCloseMenu} />
      </div>
    </div>
  );
};

export default Products;
