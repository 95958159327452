import React from 'react'
import "./NavigationHeader.css";
import { Link } from 'react-router-dom';

//-Navigation Component:
//parametros: imagem e link

const NavigationHeader = ( { logo, link } ) => {

  return (
    <div className="navigation-container">
      <Link to={link}>
        <img className="logo" alt="logo" src={logo} />
      </Link>
      <div></div>
    </div>
  );
};

export default NavigationHeader;