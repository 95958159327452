import React from 'react';
import './Properties.css';


//Recebe um tipo de categoria e o seu respectivo valor


/* const categories = [
  {
    "id": 1,
    "name": "Type of Product",
    "description": "Fit",
  },
  {
    "id": 2,
    "name": "Colection",
    "description": "sdadsa",
  },
  {
    "id": 3,
    "name": "Category",
    "description": "Adult",
  },
  {
    "id": 4,
    "name": "Fashion",
    "description": "Female"
  },
  {
    "id": 1,
    "name": "Type of Product",
    "description": "Fit",
  },
  {
    "id": 2,
    "name": "Colection",
    "description": "sdadsa",
  },
  {
    "id": 3,
    "name": "Category",
    "description": "Adult",
  },
  {
    "id": 4,
    "name": "Fashion",
    "description": "Female"
  },

]

*/

const Properties = ({ categories }) => {
  return (
    <div className="categoria-container">
      {categories.map((categories) => (
        <div key={categories.id} className="categoria-item">
          <div className="item-info">
            <p className="name">{categories.name}</p>
            <p className="properties-description">{categories.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Properties;
