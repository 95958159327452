import React from 'react';
import './IndicatorWeight.css'; // Import the CSS file

const IndicatorWeight = ({ icon, levelselected }) => {
  const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  //const [currentlevel, setCurrentLevel] = useState(1);

  const getClassForLevel = (currentlevel,level) => {
    //Escala antes do selected
    if (level !== null && level <= currentlevel) {
      switch (level) {
        case 1:
          return 'level-1';
        case 2:
          return 'level-2';
        case 3:
          return 'level-3';
        case 4:
          return 'level-4'
        case 5:
          return 'level-5'
        case 6:
          return 'level-6'
        case 7:
          return 'level-7'
        case 8:
          return 'level-8'
        case 9:
          return 'level-9'
        default:
          return 'level-0'; 
      }
    }


      if (level !== null && level > currentlevel) {
        switch (level) {
          case 1:
            return 'opacity-1';
          case 2:
            return 'opacity-2';
          case 3:
            return 'opacity-3';
          case 4:
            return 'opacity-4'
          case 5:
            return 'opacity-5'
          case 6:
            return 'opacity-6'
          case 7:
            return 'opacity-7'
          case 8:
            return 'opacity-8'
          case 9:
            return 'opacity-9'
          default:
            return 'level-0'; // Para outros níveis, não adiciona classe
        }
    }
    return '';
  };


  //Verifica se Normal Scale está selected ou não
  const getNormalScale = (currentlevel) => {
    switch (currentlevel) {
      case 1:
      case 2:
      case 3:
        return 'scales-1 selected'; 
      default:
        return 'scales-1'; 
    }
  }

  // Verifica se medium scale está selected ou não
  const getMediumScale = (currentlevel) => {
    switch (currentlevel) {
      case 4:
      case 5:
      case 6:
        return 'scales-2 selected'; 
      default:
        return 'scales-2'; 
    }
  }

    // Verifica se high scale está selected ou não
    const getHighScale = (currentlevel) => {
      switch (currentlevel) {
        case 7:
        case 8:
        case 9:
          return 'scales-2 selected'; 
        default:
          return 'scales-2'; 
      }
    }


  return (
    <div className="icon-scale"> 
    <div className="weight-scale">
    <div className={`${getNormalScale(levelselected)}`}>Normal</div>
    <div className={`${getMediumScale(levelselected)}`}>Medium</div>
    <div className={`${getHighScale(levelselected)}`}>High</div>
    </div>

    <div className="icon-row">
      {levels.map(level => (
        <div
          key={level}
          className={`icon-wrapper ${getClassForLevel(levelselected,level)} ${levelselected === level ? 'selected' : ''}`}
          >
          <img src={icon} className='icon-image' alt=""/>
        </div>
      ))}
      </div>
    </div>
  );
};

export default IndicatorWeight;
