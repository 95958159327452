import "./Navbar.css"
import { Link, useLocation } from 'react-router-dom';


import homeicon from '../../../img/home.svg';
import productsicon from '../../../img/products.svg';
import searchicon from '../../../img/search.svg';
import menu from '../../../img/menu.svg';
import dpp from  '../../../img/dpp_logo.svg';
import productsIconSelected from '../../../img/products-selected.svg';



const Navbar = ({MenuClick}) => {

 // Obtém a rota atual
 const location = useLocation();
  
 // Verifica a rota atual
 const isProductsRoute = location.pathname === '/view';





  return (
    <div className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-item">
          <img src={homeicon} alt="Home"></img>
          Home
        </Link>

        <Link to="/view" className="navbar-item">
          <img
            src={isProductsRoute ? productsIconSelected : productsicon}
            alt="Ícone"
          />
          Products
        </Link>
      </div>
      <div className="navbar-center">
        <a href="#" className="navbar-item-center">
          <img src={dpp} alt="Ícone"></img>
        </a>
      </div>
      <div className="navbar-right">
        <Link to="/search" className="navbar-item">
          <img src={searchicon} alt="Ícone"></img>Search
        </Link>
        <a href="#" className="navbar-item" onClick={MenuClick}>
          <img src={menu} alt="Ícone"></img>
          Menu
        </a>
      </div>
    </div>
  );
}

export default Navbar
