import React from "react";
import "./NotFound.css";
import NavigationHeader from "../../components/v1/NavigationHeader";
import Error404 from "../../components/v1/Error404";

const NotFound = () => {

  document.addEventListener('touchmove', function (e) {
    e.preventDefault();
  }, { passive: false });

  return (
    <div className="notfound-container">
      <div className="notfound-header">
        <NavigationHeader logo="/images/logo-dpp-texjourney-04.svg" link="/" />
      </div>
      <div className="notfound-error404">
        <Error404 error_text="It seems that your passport is not valid. Try again later!" />
      </div>
    </div>
  );
};

export default NotFound;
