import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from 'react-swipeable';
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import "./Product.css";
import ProductHeaderV2 from "../../components/v2/ProductHeaderV2";
import ProductGallery from "../../components/v2/product/ProductGallery";
import ScoreInfo from "../../components/v2/ScoreInfo";
import ScoreVertical from "../../components/v2/ScoreVertical";
import IndicatorInfo from "../../components/v2/IndicatorInfo";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import GeoMap from "../../components/v2/GeoMap";
import Journey from "../../components/v2/Journey";
import AditionalInfo from "../../components/v2/Aditionalinfo";
import Properties from "../../components/v2/Properties";
import BillOfMaterials from "../../components/v2/BillOfMaterials";
import CareInstructions from "../../components/v2/CareInstructions";
import MaintenanceDiscard from "../../components/v2/MaintenanceDiscard";
//imports dos jsons para mapear tradução
import countries from './jsons/countries.json';
import colors from './jsons/colors.json';
import fashions from './jsons/fashion.json';
import collections from './jsons/collection.json';
import fits from './jsons/fit.json';
import seasons from './jsons/seasons.json';
import product_type from './jsons/type.json';
import SimilarProducts from "../../components/v2/SimilarProducts";
//Icones corrigidos
import BillOfMaterialsIcon from "../../img/BillOfMaterials.svg";
import CareInstructionsIcon from "../../img/CareInstructions.svg";
import PropertiesIcon from "../../img/Properties.svg";
import RepairDiscardIcon from "../../img/RepairDiscard.svg";

//pagina para mostrar o produto e todas as informações do mesmo
//será esta a pagina que recebe todos os componentes
//contem função para verificar a existencia do produto a apresentar


const Product = ({ productList }) => {
  const [queryParameters] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const touchAreaRef = useRef(null);
  const billOfMaterialsRef = useRef(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/view');
  };

  const handleMainpage = () => {
    setCurrentPage(0);
  }

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const carouselRef = useRef();

  const handleNextClick = () => {
      carouselRef.current.goToNextSlide();
  };

  const handlePrevClick = () => {
      carouselRef.current.goToPrevSlide();
  };


  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });



  //PAGINA 5
  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(1);

  //-----------------VERIFICAÇÕES--------------------
  const getCountryDescription = (code) => {
    const country = countries.find((country) => country.code === code);
    return country ? country.description.en : "Unknown Country";
  };

  const getColorDescription = (code) => {
    const color = colors.find((color) => color.code === code);
    return color ? color.description.en : "-";
  };

  const getFashionDescription = (code) => {
    const fashion = fashions.find((fashion) => fashion.code === code);
    return fashion ? fashion.description.en : "-";
  };

  const getFitsDescription = (code) => {
    const fit = fits.find((fit) => fit.code === code);
    return fit ? fit.description.en : "-";
  };

  const getProductTypeDescription = (code) => {
    for (const type of product_type) {
      const product_type_item = type.options.find(
        (product_type_item) => product_type_item.code === code
      );

      if (product_type_item) {
        return product_type_item.description.en;
      }
    }
    return "-";
  };
  

  const getSeasonsDescription = (code) => {
    const season = seasons.find((season) => season.code === code);
    return season ? season.description.en : "-";
  };

  const getCollectionDescription = (code) => {
    const collection = collections.find(
      (collection) => collection.code === code
    );
    return collection ? collection.description.en : "-";
  };
  //-------------FIM DAS VERIFICAÇÕES----------------

  

  const handleToggleMap = () => {
    setIsMapExpanded(!isMapExpanded);
  };

useEffect(() => {
  const handleTouchMove = (e) => {
    e.preventDefault(); // Prevent default scroll handling
  };

  document.addEventListener("touchmove", handleTouchMove, { passive: true });

  return () => {
    document.removeEventListener("touchmove", handleTouchMove);
  };
}, []);



  //num max de paginas
  const maxPages = 5;
  const [startY, setStartY] = useState(0);
  const swipeThreshold = 70; // Adjust this threshold as needed

  useEffect(() => {
    // Simulate loading time, replace with your actual loading logic
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 0);

    return () => clearTimeout(timeout); // Cleanup on component unmount
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Eventualmente adicionar para uma pagina de Loading...
  }

  // Procura o produto na lista pelo seu ID
    const product = productList.find(
      (p) => p.id === queryParameters.get("productId")
    );

  // Se o produto não existir, dá return à pagina NotFound
    if (!product) {
    return <Navigate to="/error" replace />;
  }

  const returnToMainPage = () => {
    setCurrentPage(0); // define a pagina para a primeira (main product)
  };

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

const handleTouchMove = (event) => {
  if (!startY) return;

  const deltaY = event.touches[0].clientY - startY;

  const billOfMaterials = billOfMaterialsRef.current;
  const touchArea = touchAreaRef.current;
  
  // Check se o touch é no bill ofmaterals
  if (billOfMaterials && billOfMaterials.contains(event.target)) {
    const scrollTop = billOfMaterials.scrollTop;
    const scrollHeight = billOfMaterials.scrollHeight;
    const clientHeight = billOfMaterials.clientHeight;

    if (deltaY < 0 && scrollTop + clientHeight < scrollHeight) {
      // Scroll down no BOM
      return;
    } else if (deltaY > 0 && scrollTop > 0) {
      // Scroll up no BOM
      return;
    }
  }

  if (touchArea && touchArea.contains(event.target)) {
    return; // n faz swipes nas ref de touchArea
  }

  if (Math.abs(deltaY) > swipeThreshold) {
    if (deltaY < 0) {
      handlePageDown();
    } else {
      handlePageUp();
    }
    setStartY(0); // Reset startY after handling the swipe
  }
};

  const handleTouchMoveDebounced = debounce(handleTouchMove, 10);

  const handleTouchEnd = () => {
    setStartY(0); // Reset startY on touch end
  };
  

  const handlePageUp = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage - 1;
      return newPage >= 0 ? newPage : 0;
    });
  };

  const handlePageDown = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      return newPage < maxPages ? newPage : maxPages - 1;
    });
  };

  const getPageTitle = (currentPage) => {
    switch (currentPage) {
      case 1:
        return "Index";
      case 2:
        return "Detailed Index";
      case 3:
        return "The Journey";
      case 4:
        return "Information";
      default:
        return "";
    }
  };
  

  const pageTitle = getPageTitle(currentPage);

  let pageContent;

  const dataDetailedIndex = [
    {
      id: 0,
      title: "Water Consumption",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.water_consumption.value +
          " litres of water were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total litres of water consumed in the production of this article.",
      },
      icon: "./images/icon_water_v2.svg",
      value: product.dpp.product_info.detailed_index.water_consumption.value,
      units: product.dpp.product_info.detailed_index.water_consumption.units,
      scale: product.dpp.product_info.detailed_index.water_consumption.scale,
      production_activities:
        product.dpp.product_info.detailed_index.water_consumption
          .production_activities,
    },
    {
      id: 1,
      title: "Carbon Footprint",
      description: {
        indicator:
          product.dpp.product_info.detailed_index.carbon_footprint.value +
          " kg of CO2 were produced to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total carbon footprint produced in the production of this article.",
      },
      icon: "./images/icon_co2_v2.svg",
      value: product.dpp.product_info.detailed_index.carbon_footprint.value,
      units: product.dpp.product_info.detailed_index.carbon_footprint.units,
      scale: product.dpp.product_info.detailed_index.carbon_footprint.scale,
      production_activities:
        product.dpp.product_info.detailed_index.carbon_footprint
          .production_activities,
    },
    {
      id: 2,
      title: "Chemical Consumption",
      description: {
        indicator:
        product.dpp.product_info.detailed_index.chemical_products.value +
        " grams of chemicals were used to produce this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total chemicals used in the production of this article.",
      },
      icon: "./images/icon_chemical_v2.svg",
      value: product.dpp.product_info.detailed_index.chemical_products.value,
      units: product.dpp.product_info.detailed_index.chemical_products.units,
      scale: product.dpp.product_info.detailed_index.chemical_products.scale,
      production_activities:
        product.dpp.product_info.detailed_index.chemical_products
          .production_activities,
    },
    {
      id: 3,
      title: "Recovered Waste",
      description: {
        indicator:
        product.dpp.product_info.detailed_index.recovered_waste.value +
        " % of recovered waste in the production of this article, distributed among the following production activities.",
        complexgraph:
          "This data refers to historical values obtained/ attained in the Portuguese T&C industry.",
        main: "This value represents the total recovered waste in the production of this article.",
      },
      icon: "./images/icon_recovered_waste_v2.svg",
      value: product.dpp.product_info.detailed_index.recovered_waste.value,
      units: product.dpp.product_info.detailed_index.recovered_waste.units,
      scale: product.dpp.product_info.detailed_index.recovered_waste.scale,
      production_activities:
        product.dpp.product_info.detailed_index.recovered_waste
          .production_activities,
    },
  ];




  const data = [
    {
      image: PropertiesIcon,
      text: "Properties",
      id: 0,
    },

    {
      image: CareInstructionsIcon,
      text: "Care Instructions",
      id: 1,
    },
    {
      image: RepairDiscardIcon,
      text: "Repair and Discard",
      id: 2,
    },
    {
      image: BillOfMaterialsIcon,
      text: "Bill of Materials",
      id:3,
    },
  ];


  const weight = product.dpp.product_info.properties.weight;
  const displayWeight = weight !== null ? weight + " kg" : "-";  

const categoriesData = [
  {
    name: "Type of Product",
    description: getProductTypeDescription(
      product.dpp.product_info.properties.type
    ),
  },
  {
    name: "Fit",
    description: getFitsDescription(product.dpp.product_info.properties.fit),
  },
  {
    name: "Category",
    description: getCollectionDescription(
      product.dpp.product_info.properties.collection
    ),
  },
  {
    name: "Fashion",
    description: getFashionDescription(
      product.dpp.product_info.properties.fashion
    ),
  },
  {
    name: "Color",
    description:
      product.dpp.product_info.properties.color &&
      product.dpp.product_info.properties.color.length > 0
        ? product.dpp.product_info.properties.color
            .map((color) => getColorDescription(color))
            .join(", ")
        : "-",
  },
  {
    name: "Colection",
    description:
      product.dpp.product_info.properties.season &&
      product.dpp.product_info.properties.season.length > 0
        ? product.dpp.product_info.properties.season
            .map((season) => getSeasonsDescription(season))
            .join(", ")
        : "-",
  },
  {
    name: "Weight",
    description: displayWeight,
  },
  {
    name: "Variants",
    description: product.dpp.product_info.properties.number_of_variants || "-",
  },
  {
    name: "Reciclability",
    description:
      product.dpp.product_info.properties.reciclability !== null
        ? product.dpp.product_info.properties.reciclability
          ? "Yes"
          : "No"
        : "-",
  },
  {
    name: "Microplastics",
    description:
      product.dpp.product_info.properties.microplastics !== null
        ? product.dpp.product_info.properties.microplastics
          ? "Yes"
          : "No"
        : "-",
  },
  {
    name: "Dangerous Chemicals",
    description:
      product.dpp.product_info.properties.dangerous_chemicals !== null
        ? product.dpp.product_info.properties.dangerous_chemicals
          ? "Yes"
          : "No"
        : "-",
  },
];



   const longtext_title= "Maintenance and Discard";

  if (currentPage === 0) {
    pageContent = (
      <div className="page1-container-v2">
        <div className="header-content-v2">
          <img
            src="./images/icon_arrow_back.svg"
            alt="product-arrow-goback"
            className="product-arrow-goback"
            onClick={handleBackClick}
          />
          <div className="header-content-v2-content">
            <div className="header-content-v2-title">
              {product.dpp.product_info.name}
            </div>
            <div className="header-content-v2-text">
              {product.dpp.product_info.brand} · Made In{" "}
              {getCountryDescription(product.dpp.product_info.made_in)}
            </div>
          </div>
          <img
            src="./images/icon_interrogation.svg"
            alt="product-interrogation"
            className="product-interrogation"
          />
        </div>
        <div className="product-maindiv-container-v2">
          <ProductGallery
            recycled={product.dpp.product_info.composition.recycled}
            compositionList={product.dpp.product_info.composition.fibers}
            certifications={product.dpp.product_info.certifications}
            productImgs={product.dpp.product_info.img}
            touchAreaRef={touchAreaRef}
          />
        </div>
        <div className="product-maindiv-score-v2">
          <ScoreInfo
            currentPage={0}
            icon={"./images/icon_AF.svg"}
            title={"Index"}
            description={""}
            score={product.dpp.product_info.score}
          />
        </div>
        <div className="icon-arrows-div">
          <img
            src="./images/icon_swipe_arrows.svg"
            alt="icon-arrows"
            className="icon-arrows"
          />
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 1) {
    pageContent = (
      <div className="page2-container-v2">
        <div className="product-maindiv-page">
          <div className="div-score-sac-ses-v2">
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_AF.svg"}
              title={"Index"}
              description={
                "The product's overall index considering the 4 dimensions of sustainability (environmental, circular, economical and social). A higher rating represents a more sustainable product."
              }
              score={product.dpp.product_info.score}
            />
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_sac_v2.svg"}
              title={"Environmental and Circularity"}
              description={
                "Classification based on the product's performance in environmental and circular aspects."
              }
              score={product.dpp.product_info.sac}
            />
            <ScoreInfo
              currentPage={1}
              icon={"./images/icon_ses_v2.svg"}
              title={"Economical and Social"}
              description={
                "Classification based on the economical and social performance of the value chain."
              }
              score={product.dpp.product_info.ses}
            />
          </div>
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 2) {
    pageContent = (
      <div className="page3-container">
        <div className="div-journey-v2">
          <IndicatorInfo data={dataDetailedIndex} />
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 3) {
    pageContent = (
      <div className="page4-container">
        <div className="product-maindiv-page4-v2">
          <GeoMap
            journeyTotal_distance={
              product.dpp.product_info.journey.total_distance
            }
            journeyTotal_countries={
              product.dpp.product_info.journey.total_countries
            }
            journeyActivities={
              product.dpp.product_info.journey.production_activities
            }
            isExpanded={isMapExpanded}
            onToggleMap={handleToggleMap}
            touchAreaRef={touchAreaRef}
          />
          {!isMapExpanded && (
            <div className="product-div-journey">
              <Journey
                steps={product.dpp.product_info.journey.production_activities}
              />
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
    );
  } else if (currentPage === 4) {
    pageContent = (
      <div className="page5-container">
        <div className="product-maindiv-page5-v2-carousel">
          <AditionalInfo
            data={data}
            currentVisibleSlide={currentVisibleSlide}
            setCurrentVisibleSlide={setCurrentVisibleSlide}
            refNextBack={carouselRef}
          />
        </div>
        <div className="product-maindiv-page5-v2" {...handlers}>
          {currentVisibleSlide === 0 && (
            <div className="properties">
              {<Properties categories={categoriesData} />}
            </div>
          )}
          {currentVisibleSlide === 1 && (
            <div className="care-instructions-products">
              {
                <CareInstructions
                  care_instructions={product.dpp.product_info.care_instructions}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 2 && (
            <div className="maintenance-discard">
              {
                <MaintenanceDiscard
                  longtext_text={
                    product.dpp.product_info.maintenance_repair_instructions
                  }
                  longtext_title={longtext_title}
                />
              }
            </div>
          )}
          {currentVisibleSlide === 3 && (
            <div className="bill-of-materials" ref={billOfMaterialsRef}>
              {
                <BillOfMaterials
                  data={product.dpp.product_info.bill_of_materials}
                ></BillOfMaterials>
              }
            </div>
          )}
        </div>
        <div className="navbar-footer">
          <Navbar MenuClick={handleOpenMenu}></Navbar>
          <OverlayComponent
            touchAreaRef={touchAreaRef}
            isOpen={isMenuOpen}
            onClose={handleCloseMenu}
          />
        </div>
        <div
          className="SimilarProducts"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
          ref={touchAreaRef}
        >
          <SimilarProducts
            productList={product.dpp.product_info.similar_products}
            handleMainpage={handleMainpage}
          />
        </div>
      </div>
    );
  } 

  return (
    <div className="product-fixed-page">
      <div className="product-container">
        <div className="page-indicator">
          {Array.from({ length: maxPages }, (_, index) => (
            <div
              key={index}
              className={`indicator-dot ${
                index === currentPage ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
        {currentPage !== 0 && (
          <div className="header-content-v2">
            <img
              src="./images/icon_arrow_back.svg"
              alt="product-arrow-goback"
              className="product-arrow-goback"
              onClick={handleBackClick}
            />
            <div className="header-content-v2-content center">
              <div className="header-content-v2-title center">{pageTitle}</div>
            </div>
            <img
              src="./images/icon_interrogation.svg"
              alt="product-interrogation"
              className="product-interrogation"
            />
          </div>
        )}
        {currentPage !== 0 && (
          <div className="header-v2">
            <ProductHeaderV2
              handleNavigation={returnToMainPage}
              product_info={product.dpp.product_info}
              score={product.dpp.product_info.score}
            />
            <ScoreVertical score={product.dpp.product_info.score} />
          </div>
        )}
        <div
          className="swipe-main-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMoveDebounced}
          onTouchEnd={handleTouchEnd}
        >
          {pageContent}
        </div>
      </div>
    </div>
  );
};

export default Product;
