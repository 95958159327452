import React, { useState, useRef, useEffect } from 'react';
import "./MaintenanceDiscard.css";
import Modal from "./Modal";

//o que recebemos
/* longtext_title={"Maintenance and Discard"}
        longtext_text={
          "Opa qualquer coisa sobre reparar a peça e deitar ao lixo porque é podre."
        } */

const MaintenanceDiscard = ({ longtext_title, longtext_text }) => {
  const [showModal, setShowModal] = useState(false);
  const [textOverflow, setTextOverflow] = useState(false);
  const textRef = useRef(null);

  const displayText = longtext_text || "The information was not provided.";
  const alignItemsStyle = longtext_text ? "flex-start" : "center";
  const shouldCenterText = displayText === "The information was not provided.";

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const textElement = textRef.current;

    if (textElement.scrollHeight > textElement.clientHeight) {
      setTextOverflow(true);
    } else {
      setTextOverflow(false);
    }
  }, [longtext_text]);

  return (
    <div className="longtext-container">
      <Modal isOpen={showModal} onClose={closeModal} title={longtext_title}>
        {displayText}
      </Modal>
      <div className="longtext-title">{longtext_title}</div>
      <div
        ref={textRef}
        className={`longtext-text ${shouldCenterText ? "centered-text" : ""}`}
        style={{ alignItems: alignItemsStyle }}
      >
        {displayText}
      </div>
      {!showModal && textOverflow && (
        <button className="see-more-button" onClick={openModal}>
          <img
            alt="see-more-button-eye"
            src="./images/see-more-button-eye.svg"
            className="see-more-button-eye-img"
          />
          See more
        </button>
      )}
    </div>
  );
};

export default MaintenanceDiscard;
