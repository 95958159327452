import React, { useState } from 'react';
import "./ProductGallery.css";
import Certifications from "../Certifications";

//galeria para mostrar imagens do protudo na pagina do mesmo
//atribui uma imagem principal e faz o map das restantes em especie de icon
//recebe como atribuito uma lista de imagens
/* const productImgs = [
"../images/img_produto.png",
"../images/icon_sewing.svg",
"../images/icon_weaving.svg",
"../images/icon_knitting.svg",
"../images/icon_info.svg",
"../images/icon_client.svg",
]; */

const ProductGallery = ({
  productImgs,
  recycled,
  compositionList,
  certifications,
  touchAreaRef,
}) => {
  const [mainImg, setMainImg] = useState(productImgs[0]);

  const handleThumbnailClick = (img) => {
    setMainImg(img);
  };

  return (
    <div className="productgallery-container">
      <Certifications
        touchAreaRef={touchAreaRef}
        recycled={recycled}
        compositionList={compositionList}
        certifications={certifications}
      />
      <img src={mainImg} alt="img-main-produto" className="img-main-produto" />
      <div className="img-thumbnails">
        {productImgs
          .filter((img) => img !== mainImg)
          .map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`thumbnail-${index}`}
              className="img-thumbnail"
              onClick={() => handleThumbnailClick(img)}
            />
          ))}
      </div>
    </div>
  );
};

export default ProductGallery;
