import React from 'react';
import './ScoreVertical.css';




const ScoreVertical = ({ score }) => {

  const getLetterGrade = (score) => { if (score < 0 || score > 100) {
    return "Null"; } 
    else if (score >= 85.0) { return "A"; } 
    else if (score >= 75.0) { return "B"; } 
    else if (score >= 65.0) { return "C"; } 
    else if (score >= 55.0) { return "D"; } 
    else if (score >= 45.0) { return "E"; } 
    else if (score < 45.0) { return "F"; } 
    else { return "Null"; } };


  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
  const activeLetter = getLetterGrade(score);

  //console.log(activeLetter);

  return (
    <div className="scale-vertical">
      {letters.map((letter, index) => (
        <div key={index} className={`letter-vertical ${letter === activeLetter ? 'active' : ''}`}>
          {letter}
        </div>
      ))}
    </div>
  );
};

export default ScoreVertical;