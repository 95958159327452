import React, { useState } from "react";
import "./Homepage.css";

//Componentes
import Navbar from "../../components/v2/homepage/Navbar";
/* import AssociatedBrands from "../../components/v2/homepage/AssociatedBrands"; */
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import SlideContentV2 from "../../components/v2/homepage/SlideContentV2";

/* const brands = [
  { id: 1, logo: salsa_logo },
  { id: 2, logo: sonae_logo },
  { id: 3, logo: zippy_logo },
]; */


const homepageSlideContent = [
    {
      id: 1,
      img: "../images/dpp-1.png",
    },
    {
      id: 2,
      img: "../images/dpp-2.png",
    },
    {
      id: 3,
      img: "../images/dpp-3.png",
    },
  ];

const Homepage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="home-container">
      <div className="slider-container">
        <SlideContentV2 homepageSlideContent={homepageSlideContent}> </SlideContentV2>
      </div>
      {/* <div className="associated-brands">
        <AssociatedBrands marcas={brands}></AssociatedBrands>
      </div> */}
      <div className="navbar-footer">
        <Navbar MenuClick={handleOpenMenu}></Navbar>
        <OverlayComponent isOpen={isMenuOpen} onClose={handleCloseMenu} />
      </div>
    </div>
  );
};

export default Homepage;
