import React from 'react'
import "./Error404.css";

//componente para apresentar caso a pagina do artigo textil nao esteja correta/encontrada (erro 404)
//apresenta uma imagem e um texto que é tido como parametro

const Error404 = ({ error_text }) => {
  return (
    <div className='error404-container'>
      <h1 className="error404-title">Whoops!!!</h1>
      <img
        className="error404-img"
        alt="error404-img"
        src="/images/error404.svg"
      />
      <p className="error404-text">{error_text}</p>
    </div>
  );
};

export default Error404;