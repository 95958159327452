import React, { useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import './Modal.css';

const modalRoot = document.getElementById('modal-root') || document.body;

const Modal = ({ isOpen, onClose, title, children }) => {
  const modalRef = useRef(document.createElement("div"));

  const handleCleanup = useCallback(() => {
    modalRoot.removeChild(modalRef.current);
  }, []);

  useEffect(() => {
    modalRoot.appendChild(modalRef.current);

    return handleCleanup;
  }, [handleCleanup]);

  const handleModalClick = (event) => {
    // Handle click events within the modal content
    // You can add additional logic here if needed
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-border" onClick={handleModalClick}>
        <button className="modal-close" onClick={onClose}>
          <img
            alt="close-modal-img"
            src="./images/close_button.svg"
            className="close-modal-img"
          />
        </button>
        <div className="modal-title">{title}</div>
        <div
          className="modal-content"
          onTouchStart={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>,
    modalRef.current
  );
};

export default Modal;
