import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Homepage from '../pages/v2/Homepage';
import NotFound from '../pages/v1/NotFound';
import Product from "../pages/v2/Product.jsx";
import Products from "../pages/v2/Products.jsx";
import Search from "../pages/v2/Search.jsx";
import jsonData from "../ProductList.json";
import searchList from "../pages/v2/jsons/products_page.json";

const App = () => {

  //lista de produtos (a ir buscar a outro ficheiro - ProductList.json)
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    setProductList(jsonData);
  }, []);

  // "./images/img_testeee.png"


  const isValidRoute = (route) => {
    // Check if the route is one of the valid routes
    return ["/products", "/view", "/search", "/", "notfound"].includes(route);
  };

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router scrollBehavior="auto">
        <Routes>
          <Route path="/" index element={<Homepage />} />
          <Route
            path="/products"
            element={<Product productList={productList} />}
          />
          <Route path="/view" element={<Products productList={searchList} />} />
          <Route path="/search" element={<Search productList={searchList} />} />
          <Route path="/error" element={<NotFound />} />
          <Route
            path="*"
            element={isValidRoute("*") ? null : <Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default App;
