import React, { useRef, useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "./SlideProducts_carousel.css";
import { useNavigate } from "react-router-dom";

export const Card = React.memo(function (props) {
  const { data, dataIndex, slideIndex, isCenterSlide, swipeTo } = props;
  const navigate = useNavigate();

  const handleProductPage = (productId) => {
    navigate(`/products?productId=${productId}`);
  };

  const handleClick = () => {
    if (isCenterSlide) {
      // Clicked on the centered slide and go to ProductPage
      handleProductPage(id);
    } else if (slideIndex === -1 || slideIndex === 1) {
      // Clicked on the adjacents to center
      swipeTo(slideIndex);
    }
  };

  const { dpp } = data[dataIndex];
  const { id } = data[dataIndex];

  const opacity = isCenterSlide ? 1 : 0.4;

  return (
    <div
      onClick={handleClick}
      className="my-slide-component"
      style={{ opacity }}
    >
      <div className="slideproduct-carousel-image">
        <img
          alt={dpp.product_info.name}
          draggable={false}
          src={dpp.product_info.img}
        />
      </div>
      <div className="slideproduct-carousel-texts">
        {isCenterSlide && (
          <>
            <div className="slideproduct-carousel-name">
              {dpp.product_info.name}
            </div>
            <div className="slideproduct-carousel-brand">
              {dpp.product_info.brand}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const SlideProductsCarousel = ({ productList, title_slideproducts }) => {
  const carouselRef = useRef();

  useEffect(() => {
    const handleTouch = () => {

      clearInterval(autoplayInterval);

      const newAutoplayInterval = setInterval(() => {
        if (carouselRef.current) {
          carouselRef.current.goNext();
        }
      }, 3500);

      autoplayInterval = newAutoplayInterval;
    };

    let autoplayInterval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.goNext();
      }
    }, 3500);

    document.addEventListener("touchstart", handleTouch);

    return () => {
      clearInterval(autoplayInterval);
      document.removeEventListener("touchstart", handleTouch);
    };
  }, []);

  const customscale = [1, 0.8, 0.2, 0];

  return (
    <div className="slideproducts-carousel-maindiv">
      <div className="slideproducts-carousel-title">{title_slideproducts}</div>
      <ResponsiveContainer
        carouselRef={carouselRef}
        render={(parentWidth, carouselRef) => {
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={(props) => <Card {...props} />}
              slideWidth={100}
              carouselWidth={parentWidth}
              data={productList}
              currentVisibleSlide={3}
              maxVisibleSlide={5}
              swipeSpeed={0.3}
              customScales={customscale}
              useGrabCursor
            />
          );
        }}
      />
    </div>
  );
};

export default SlideProductsCarousel;
