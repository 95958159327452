import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./ScoreLetterScale.css";

//componente para mostrar o score (LETRA) com base numa escala horizontal (ficou top demais)
// tem como parametros apenas um valor numerico do score do artigo
// tem uma função para verificar em que faixa de classificação se adequa (letras)
// atribui a cor conforme a letra definida
//contem variaveis de estilo (arrowPosition) e (arrowScorePosition) para posicionar dinamicamente a seta e o valor recebido

const ScoreLetterScale = ({ score }) => {
  //posição da seta quando recebe o valor
  const [animatedScore, setAnimatedScore] = useState(0);
  const [arrowPosition, setArrowPosition] = useState(99.2 - score + "%");
  const [arrowScorePosition, setArrowScorePosition] = useState(95.6 - score + "%");

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.05,
  });

  useEffect(() => {
    // Check if the animation has been played
    const shouldPlayAnimation = localStorage.getItem(
      "letterScoreAnimationPlayed"
    );

    // If the animation hasn't been played and the component is in view, start the animation
    if (!shouldPlayAnimation && inView) {
      const animationDuration = 1800;
      const start = Date.now();

      const updateScore = () => {
        const elapsed = Date.now() - start;
        const progress = elapsed / animationDuration;

        if (progress < 1) {
          setAnimatedScore(score * progress);
          setArrowPosition(99.2 - score * progress + "%");
          setArrowScorePosition(95.6 - score * progress + "%");
          requestAnimationFrame(updateScore);
        } else {
          setAnimatedScore(score);
          setArrowPosition(99.2 - score + "%");
          setArrowScorePosition(95.6 - score + "%");

          // Set the flag in localStorage indicating that the animation has been played
          localStorage.setItem("letterScoreAnimationPlayed", true);
        }
      };

      updateScore();
    } else {
      // If the animation has been played or the component is out of view, set the final score without animation
      setAnimatedScore(score);
    }
  }, [score, inView]);

  const getLetterGrade = (score) => {
    if (score < 0 || score > 100) {
      return "Null";
    } else if (score >= 85.0) {
      return "A";
    } else if (score >= 75.0) {
      return "B";
    } else if (score >= 65.0) {
      return "C";
    } else if (score >= 55.0) {
      return "D";
    } else if (score >= 45.0) {
      return "E";
    } else if (score < 45.0) {
      return "F";
    } else {
      return "Null";
    }
  };

  const letterGrade = getLetterGrade(animatedScore);

  const getLetterGradeColor = (grade) => {
    switch (grade) {
      case "A":
        return "#005000";
      case "B":
        return "#00CC00";
      case "C":
        return "#FFDE69";
      case "D":
        return "#FFB366";
      case "E":
        return "#FF8000";
      case "F":
        return "#FF0000";
      default:
        return "#40454B";
    }
  };

  const textColor = getLetterGradeColor(letterGrade);

  return (
    <div className="scales-div" ref={ref}>
      <div className="letter-scale-container">
        <div className="letter-scale">
          <div
            className="letter-arrow-score"
            style={{ color: textColor, left: arrowScorePosition }}
          >
            {letterGrade}
          </div>
          <div className="letter-arrow" style={{ left: arrowPosition }}></div>
          <div className="letter-mark" style={{ left: "100%" }}>
            <p className="letter-mark-text">0%</p>
          </div>
          <div className="letter-mark" style={{ left: "55%" }}>
            <p className="letter-mark-text">45%</p>
          </div>
          <div className="letter-mark" style={{ left: "45%" }}>
            <p className="letter-mark-text">55%</p>
          </div>
          <div className="letter-mark" style={{ left: "35%" }}>
            <p className="letter-mark-text">65%</p>
          </div>
          <div className="letter-mark" style={{ left: "25%" }}>
            <p className="letter-mark-text">75%</p>
          </div>
          <div className="letter-mark" style={{ left: "15%" }}>
            <p className="letter-mark-text">85%</p>
          </div>
          <div className="letter-mark" style={{ left: "0%" }}>
            <p className="letter-mark-text">100%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreLetterScale;
