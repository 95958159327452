import React, { useEffect, useRef, useState } from 'react';
import './ComplexGraph.css';
import Chart from 'chart.js/auto';
// imports dos jsons para mapear tradução
import prod_actvs from '../../pages/v2/jsons/prod_actvs.json';

const ComplexGraph = ({ atlist, valueObject }) => {
  const chartRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const getCompositionDescription = (code) => {
    const prod_actv = prod_actvs.find((prod_actv) => prod_actv.code === code);
    return prod_actv ? prod_actv.description.en : 'Unknown Activity';
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = chartRef.current;
      const screenWidth = window.innerWidth;

      if (screenWidth <= 600) {
        canvas.height = 160;
        canvas.width = 300;
      } else if (screenWidth <= 1024) {
        canvas.height = 190;
      } else {
        canvas.height = 200;
        canvas.width = 400;
      }
    };

    // Set initial height based on screen width
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!atlist || atlist.length === 0) {
      // Create empty chart if no data
      const emptyDonutData = {
        datasets: [
          {
            data: [1],
            borderWidth: 0,
            backgroundColor: '#D1D1D1',
            weight: 0.05,
          },
        ],
      };

      const emptyDonutConfig = {
        type: 'doughnut',
        data: emptyDonutData,
        options: {
          layout: { padding: 10 },
          cutout: '60%',
          plugins: { tooltip: { enabled: false }, legend: { display: false } },
          hover: { mode: null },
          animation: false,
        },
      };

      const emptyDonutChart = new Chart(chartRef.current, emptyDonutConfig);
      return () => emptyDonutChart.destroy();
    }

    // Filter out items with a value of 0
    const filteredData = atlist.filter(item => item.value > 0);

    const imageMapping = {
      PA_SPINNING: "./images/spinning_graph_v2.svg",
      PA_WEAVING: "./images/weaving_graph_v2.svg",
      PA_KNITTING: "./images/knitting_graph_v2.svg",
      PA_ULTIMATION: "./images/dyeing_and_finishing_graph_v2.svg",
      PA_MANUFACTURING: "./images/sewing_graph_v2.svg",
    };

    // Preload images
    const imagePromises = filteredData.map((item) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ item, img });
        img.onerror = () => reject();
        img.src = imageMapping[item.production_activity_code];
      });
    });

    Promise.all(imagePromises)
      .then((loadedImages) => {
        const sortedData = filteredData.sort((a, b) => b.value - a.value);

        // Calculate total value and minimum value threshold
        const totalValue = sortedData.reduce((sum, item) => sum + item.value, 0);
        const minThreshold = totalValue * 0.1;

        // Adjust values to ensure no slice is below the minimum threshold
        const adjustedData = sortedData.map((item) => ({
          ...item,
          adjustedValue: Math.max(item.value, minThreshold),
        }));

        // Normalize adjusted values to maintain the total value
        const adjustedTotal = adjustedData.reduce((sum, item) => sum + item.adjustedValue, 0);
        const normalizedData = adjustedData.map((item) => ({
          ...item,
          normalizedValue: (item.adjustedValue / adjustedTotal) * totalValue,
        }));

        // Generate vertical linear gradients for each slice
        const gradientColors = generateGradientColors(normalizedData.length);

        const data = {
          labels: normalizedData.map((item) =>
            getCompositionDescription(item.production_activity_code)
          ),
          datasets: [
            {
              data: normalizedData.map((item) => item.normalizedValue),
              backgroundColor: gradientColors,
              borderWidth: 1,
              borderColor: 'white',
              hoverBorderWidth: 0,
              hoverBorderColor: 'transparent',
              hoverOffset: 0,
            },
          ],
        };

        const doughnutLabelsLine = {
          id: 'doughnutLabelsLine',
          afterDraw(chart) {
            const { ctx } = chart;

            normalizedData.forEach((dataItem, index) => {
              const { item, img } = loadedImages.find(loaded => loaded.item.production_activity_code === dataItem.production_activity_code);
              const centerX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
              const centerY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
              const radius = Math.min(chart.chartArea.right - chart.chartArea.left, chart.chartArea.bottom - chart.chartArea.top) / 2;
              const startAngle = chart.getDatasetMeta(0).data[index].startAngle;
              const endAngle = chart.getDatasetMeta(0).data[index].endAngle;
              const middleAngle = (startAngle + endAngle) / 2;

              const iconSize = 9.5;
              const iconOffset = iconSize / 2;

              const xIcon = centerX + (radius + 3) * Math.cos(middleAngle);
              const yIcon = centerY + (radius + 3) * Math.sin(middleAngle);

              ctx.drawImage(img, xIcon + (xIcon >= centerX ? -3 : -10), yIcon + (yIcon >= centerY ? 0 : -7), iconSize, iconSize);

              const label = chart.data.labels[index];
              const labelOffset = 3.1;

              ctx.font = 'bold 10px Helvetica';
              ctx.textAlign = xIcon >= centerX ? 'left' : 'right';
              ctx.fillStyle = '#666666';
              ctx.fillText(label, xIcon + (xIcon >= centerX ? 8 : -12), yIcon + (yIcon >= centerY ? 0 : -7) + iconOffset + labelOffset);

              const radiusValue = (Math.min(chart.chartArea.right - chart.chartArea.left, chart.chartArea.bottom - chart.chartArea.top) / 2) * 0.65;
              const xStartValue = centerX + radiusValue * Math.cos(middleAngle);
              const yStartValue = centerY + radiusValue * Math.sin(middleAngle);

              const value = sortedData[index].value;
              const percentage = value.toFixed(1) + '%';
              ctx.font = 'bold 8px Helvetica';
              ctx.fillStyle = 'white';
              ctx.fillText(
                percentage,
                xStartValue + (xIcon >= centerX ? -7 : 7),
                yStartValue + (yIcon >= centerY ? 5 : 0)
              );
            });

            ctx.save();
            ctx.font = 'bold 13px Helvetica';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#1C698B';
            ctx.fillText(`${valueObject.value} ${valueObject.units}`, chart.chartArea.width / 2, chart.chartArea.height / 2 + 20);
            ctx.restore();
          },
        };

        const config = {
          type: 'doughnut',
          data: data,
          options: {
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 20,
              },
            },
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
            },
            interaction: {
              mode: 'index',
            },
            cutout: '47%',
            responsive: true,
            maintainAspectRatio: false,
          },
          plugins: [doughnutLabelsLine],
        };

        const myChart = new Chart(chartRef.current, config);

        setImagesLoaded(true);

        return () => myChart.destroy();
      })
      .catch((error) => {
        console.error('Failed to load images:', error);
      });
  }, [atlist, valueObject]);

  const generateGradientColors = (count) => {
    const colors = ['#0099CCB2', '#0097AE', '#004C71', '#0B3C6C'];
    const gradients = [];

    for (let i = 0; i < count; i++) {
      const gradient = chartRef.current?.getContext('2d').createLinearGradient(0, 290, 0, 0);
      gradient.addColorStop(0.9, colors[i % colors.length]);
      gradient.addColorStop(1, 'transparent'); // End color, could be adjusted to another color or 'transparent' if needed
      gradients.push(gradient);
    }

    return gradients;
  };

  return (
    <div className="composition-container">
      <div className="composition-line">
        <div className="container-of-donut-container">
          <canvas ref={chartRef} width="400" height="180"></canvas>
        </div>
      </div>
    </div>
  );
};

export default ComplexGraph;
